<template>
  <div id="generate" class="page-content">
    <div class="row">
      <div class="col-lg-12 mb-4">
        <div class="main-profile p-4 p-md-5">
          <div class="row">
            <div class="col-lg-12">
              <div class="heading-section">
                <h4><em>Generate</em> QR Code</h4>
              </div>
              <div class="generate-form">
                <div class="row">
                  <div class="col-md-6 pe-4 mb-4 mb-md-0">
                    <b-form @submit="onSubmit" @reset="onReset">

                      <b-form-group
                          class="mb-3"
                          id="input-group-1"
                          label="Enter URL:"
                          label-for="input-1"
                          :validated="isTextValid">
                        <b-form-input
                            id="input-1"
                            v-model="form.text"
                            type="text"
                            ref="textInput"
                            placeholder="e.g. https://example.com"
                            required>
                        </b-form-input>
                      </b-form-group>

                      <b-form-group
                          v-if="selectedStyle.showPrompt"
                          class="mb-2"
                          id="input-group-2"
                          label="Enter Prompt:"
                          label-for="input-2"
                          :validated="isPromptValid">
                        <b-textarea
                            id="input-2"
                            v-model="form.prompt"
                            placeholder="Describe the image you want, e.g. Utopian amazon rainforest"
                            rows="4"
                            required>
                        </b-textarea>
                        <div class="text-end">
                          <small class="form-text text-light">{{ form.prompt.length }} / {{ promptMaxLength }}</small>
                        </div>
                      </b-form-group>

                      <pre v-if="false" class="bg-dark text-white mb-3">{{ form }}</pre>

                      <b-form-group
                          class="mb-4"
                          id="input-group-33"
                          label="Select Style:"
                          label-for="input-2"
                          :validated="isPromptValid">
                        <div class="row px-1 style-select" :class="{'style-height-2': !selectedStyle.showPrompt}">
                          <div class="col-3 mb-3 px-2" v-for="(style) in styles" :key="style.id">
                            <div class="theme-item rounded" v-b-tooltip.hover :title="style.title" :class="{'selected': selectedStyle.id === style.id}" @click="selectStyle(style)">
                              <img class="img-fluid rounded" v-if="style.imgLink" :src="style.img" :alt="style.title">
                              <StoreImage v-else :clickable="false" :class-name="'rounded'" :src="style.img" :alt="style.title" />
                            </div>
                          </div>
                        </div>
                      </b-form-group>

                      <b-button class="submit-btn w-100" type="submit" variant="primary" :disabled="isLoading || allowGeneration || !isTextValid || !isPromptValid">Generate</b-button>

                    </b-form>
                  </div>
                  <div class="col-md-6 ps-4">
                    <div v-if="showResult">
                      <div v-if="imageData && imageData.images">
                        <div class="row">
                          <div class="col" v-for="(image, index) in imageData.images" :key="index">
                            <StoreImage class-name="border-24" :src="image"/>
                          </div>
                        </div>
                      </div>
                      <div v-else-if="imageData && imageData.images === null">
                        <div class="main-border-button border-no-active">
                          <a href="#">
                            Oops! 😑 It didn't work as expected. Please give it another shot. The AI 🤖 tried its best, but it seems like creating the image turned into a wild adventure 🌪️
                          </a>
                        </div>
                      </div>
                      <div v-else>
                        <div class="row">
                          <div class="col-12" v-if="showLoadingMessage">
                            <div class="main-border-button border-no-active mb-3">
                              <a class="loading-message" href="#">
                                Please be patient! The estimated time for your request is between 10 sec to 2 min. ⏳✨ Our AI wizards are working their magic to generate your image. It'll be ready in a moment. Take a breath, sip some coffee ☕️, and get ready for an enchanting result!
                              </a>
                            </div>
                          </div>
                          <div class="col-12">
                            <b-skeleton-img class="border-24" width="100%" style="padding-top: 100%"></b-skeleton-img>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div class="output-placeholder"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4">
            <b-alert variant="warning" show v-if="allowGeneration">
              Uh-oh! It seems like your token supply for generating new images has run out. 🚫🎨 <u><b><router-link class="text-dark" to="/profile">Get more tokens!!</router-link></b></u>
            </b-alert>
            <b-alert variant="warning" show v-else-if="showLoginAlert">
              🚀 Hold on tight! <u><b style="cursor: pointer" @click="loginWithGoogle">Sign in</b></u> to unleash the power of AI image generation! 🌟
            </b-alert>
          </div>
        </div>
      </div>
<!--      <div class="col-lg-12" v-if="showResult">-->
<!--        <div class="main-profile">-->
<!--          <div class="row">-->

<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import {doc, onSnapshot} from "firebase/firestore";
import StoreImage from "../components/StoreImage";

export default {
  name: "Generate",
  components: {StoreImage},
  data() {
    return {
      form: {
        text: '',
        prompt: ''
      },
      userPrompt: "",
      showResult: false,
      loading: false,
      unsubscribe: null,
      imageData: null,
      isLoading: false,
      showLoginAlert: false,
      showLoadingMessage: false,
      promptMaxLength: 350,
      selectedStyle: {},
      styles: [],
    }
  },
  computed: {
    ...mapState(['user', 'userDetails']),
    ...mapGetters(['db', 'getGenerateData']),
    allowGeneration() {
      // console.log("this.userDetails", this.userDetails)
      return this.userDetails && this.userDetails.tokens < 1;
    },
    isTextValid() {
      return this.form.text.length > 0 && this.form.text.length < 100;
    },
    isPromptValid() {
      return this.form.prompt.length > 0 && this.form.prompt.length < this.promptMaxLength;
    }
  },
  methods: {
    ...mapActions(['generateImage', 'loginWithGoogle', 'trackGenerateEvent', 'getThemes']),
    ...mapMutations(['setGenerateData']),
    selectStyle(style) {
      if (style.id === this.selectedStyle.id) {
        return;
      }
      if (this.selectedStyle.showPrompt === true && style.showPrompt === false) {
        this.userPrompt = this.form.prompt;
        this.form.prompt = style.prompt;
      }
      else if (this.selectedStyle.showPrompt === false && style.showPrompt === true) {
         this.form.prompt = this.userPrompt;
      }
      else {
        this.form.prompt = style.prompt;
      }
      this.selectedStyle = style;
    },
    async onSubmit(event) {
      event.preventDefault();
      this.trackGenerateEvent();
      this.setGenerateData(this.form);
      if (!this.user) {
        this.showLoginAlert = true;
        await this.loginWithGoogle();
        if (!this.user) {
          this.showLoginAlert = true;
          // console.log("User login");
          return;
        }
        this.showLoginAlert = false;
      }
      this.isLoading = true;
      try {
        this.showResult = true
        setTimeout(() => {
          // Code to be executed after 40 seconds
          this.showLoadingMessage = true;
        }, 10000); // 10 seconds in milliseconds
        const imageDocId = await this.generateImage(this.form)
        if (!imageDocId) {
          // console.log("Error generating the image")
          this.isLoading = false
          return;
        }
        const imageDocRef = doc(this.db, 'images', imageDocId)
        this.unsubscribe = onSnapshot(imageDocRef, (snapshot) => {
          const imageData = snapshot.data()
          this.imageData = imageData
          if (imageData.images && imageData.images.length) {
            this.isLoading = false
            this.showLoadingMessage = false
          }
          else if (imageData.images === null) {
            this.isLoading = false
            this.showLoadingMessage = false
          }
        });
      }
      catch (e) {
        this.isLoading = false
        // console.log("Error:", e)
      }
    },
    onReset(event) {
      event.preventDefault()
      this.form.text = ''
      this.form.prompt = ''
    },
    stopListening() {
      if (this.unsubscribe) {
        this.unsubscribe();
        this.unsubscribe = null;
      }
    }
  },
  async mounted() {
    this.form = this.getGenerateData;
     this.getThemes().then((themes) => {
       if (themes.length) {
         this.selectStyle(themes[0]);
         this.styles = themes;
       }
     });
    this.$refs.textInput.focus();
    setTimeout(() => {
      const targetSection = document.getElementById('generate');
      targetSection.scrollIntoView({ behavior: 'smooth' });
    }, 500);
  },
  beforeRouteLeave(to, from, next) {
    this.stopListening();
    next();
  },
  beforeUnmount() {
    this.stopListening();
  }
}
</script>

<style scoped lang="scss">
.loading-message{
  font-size: 14px;
}
.style-select {
  max-height: 200px;
  overflow-y: auto;
  .theme-item  {
    cursor: pointer;
    overflow: hidden;
  }
  .selected {
    border: 4px solid var(--main-color);
    padding: 6px;
  }
}
.style-height-2 {
  max-height: 350px;
}
::-webkit-scrollbar {
  width: 12px;
}
::-webkit-scrollbar-track {
  background: var(--background-color);
}
::-webkit-scrollbar-thumb {
  background: var(--main-color);
}
::-webkit-scrollbar-corner {
  background: #f1f1f1;
}
</style>
